import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import courseSettings from "../../../course-settings"
import { updateUserDetails, userDetails } from "../../services/moocfi"
import withSimpleErrorBoundary from "../../util/withSimpleErrorBoundary"
import Loading from "../Loading"

const Row = styled.div`
  font-family: "Lato" !important;
  margin-bottom: 1.5rem;
`

const ErrorMessage = styled.p`
  font-family: "Lato" !important;
  color: #e53935;
  font-weight: bold;
`

const StyledButton = withStyles({
  root: {
    background: "#1c1c1c",
    borderRadius: 4,
    /* border: ".1rem solid #A9D5F3", */
    color: "#f1f1f1",
    height: 44,
    padding: "0 24px",
    boxShadow: "none",
    "&:hover": {
      background: "black",
      boxShadow: "none",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button)

const Form = styled.form``

const Footer = styled.div`
  margin-top: 4rem;
  border-top: 2px solid #eee;
  padding: 1.2rem 3rem;
  /* display: grid;
grid-template-columns: 1fr 1fr;

div:last-of-type {
  justify-self: end;
} */
`

const InfoBox = styled.div`
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.54;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding: 0 3rem;
`

const LoginCard = styled.div`
  background: rgba(171, 234, 218, 0.1);
  border-radius: 4px;
  border: 2px solid #abeada;
  padding: 0.8rem 1rem;
  font-family: "Lato";
`

const FormContainer = styled.div`
  height: 100%;
  margin-top: 2rem;

  h4 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
    padding: 0 3rem;
    margin-bottom: 0;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 0.25rem;
`

const COURSE_VARIANT_KEYS = {
  Laurea: "LIKOMOOC",
  HAMK: "LITAMOOC",
}

const CourseOptionsEditor = (props) => {
  const [state, setState] = useState({
    submitting: false,
    error: true,
    errorObj: undefined,
    digital_education_for_all: false,
    marketing: false,
    research: undefined,
    first_name: undefined,
    last_name: undefined,
    use_course_variant: undefined,
    course_variant: null,
    course_variants: courseSettings.courseVariants,
    email: undefined,
    student_number: undefined,
    loading: true,
    focused: null,
  })

  useEffect(() => {
    const asyncFunction = async () => {
      const data = await userDetails()
      const variants = courseSettings.courseVariants
      let useCourseVariant = data.extra_fields?.use_course_variant === "t"
      let courseVariant = data.extra_fields?.course_variant ?? ""
      if (!variants.find((x) => x.organization === courseVariant)) {
        useCourseVariant = false
        courseVariant = null
      }
      setState(
        {
          first_name: data.user_field?.first_name,
          last_name: data.user_field?.last_name,
          email: data.email,
          student_number: data.user_field?.organizational_id,
          course_key: data.user_field?.course_key,
          digital_education_for_all:
            data.extra_fields?.digital_education_for_all === "t",
          use_course_variant: useCourseVariant,
          course_variant: courseVariant,
          course_variants: variants,
          marketing: data.extra_fields?.marketing === "t",
          research: data.extra_fields?.research,
          loading: false,
        },
        () => {
          validate()
        },
      )
    }
    asyncFunction()
  }, [])

  const onClick = async (e) => {
    e.preventDefault()
    if (
      state.course_variant &&
      state.course_key !== COURSE_VARIANT_KEYS[state.course_variant]
    ) {
      setState({
        ...state,
        error: true,
        errorObj: {
          message: props.t("invalidKey"),
        },
      })
      setTimeout(() => {
        setState({ ...state, error: false, errorObj: {} })
      }, 5000)
      return
    }
    setState({ ...state, submitting: true })
    let extraFields = {
      use_course_variant: state.use_course_variant,
      course_variant: state.course_variant,
      digital_education_for_all: state.digital_education_for_all,
      marketing: state.marketing,
      submitted: true,
    }
    const userField = {
      first_name: state.first_name,
      last_name: state.last_name,
      organizational_id: state.student_number,
    }
    await updateUserDetails({
      extraFields,
      userField,
    })
    setState({ ...state, submitting: false })
    props.onComplete()
  }

  const handleInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    setState({ ...state, [name]: value }, () => {
      state.submitting = false
      validate()
    })
  }

  const handleCheckboxInput = (e) => {
    const name = e.target.name
    const value = e.target.checked
    setState(...state, { [name]: value }, () => {
      validate()
    })
  }

  const handleFocus = (e) => {
    const name = e.target.name
    setState({ ...state, focused: name })
  }

  const handleUnFocus = () => {
    setState({ ...state, focused: null })
  }

  const validate = () => {
    setState((prev) => ({
      //    error: prev.research === undefined,
    }))
  }

  const handleCourseVariantCheckbox = (e) => {
    setState(
      {
        ...state,
        use_course_variant: e.target.checked,
        course_variant: "",
      },
      () => {
        window.location.reload(true)
        validate()
      },
    )
  }
  return (
    <FormContainer>
      <Loading loading={state.loading} heightHint="490px">
        <InfoBox>
          <LoginCard aria-label="Oma sähköposti">
            {props.t("loggedInWith")}
            {state.email}
          </LoginCard>
        </InfoBox>
      </Loading>
      <h4 id={props.t("studentInfo")}>{props.t("studentInfo")}</h4>
      <Form role="form">
        <InfoBox style={{ opacity: "0.9" }}>{props.t("aboutYourself")}</InfoBox>
        <Loading loading={state.loading} heightHint="490px">
          <div style={{ padding: "0 3rem" }}>
            <Row>
              <TextField
                id="etunimi"
                variant="outlined"
                type="text"
                label="Etunimi"
                autoComplete="given-name"
                name="first_name"
                inputProps={{ style: { fontFamily: "Lato" } }}
                InputLabelProps={{
                  style: { fontFamily: "Lato" },
                  shrink: state.first_name || state.focused === "first_name",
                }}
                fullWidth
                value={state.first_name}
                onChange={handleInput}
                onFocus={handleFocus}
                onBlur={handleUnFocus}
                aria-label="Etunimi"
                aria-labelledby="Opiskelijan tiedot etunimi"
              />
            </Row>

            <Row>
              <TextField
                id="sukunimi"
                variant="outlined"
                type="text"
                label={props.t("lastName")}
                autoComplete="family-name"
                name="last_name"
                inputProps={{ style: { fontFamily: "Lato" } }}
                InputLabelProps={{
                  style: { fontFamily: "Lato" },
                  shrink: state.last_name || state.focused === "last_name",
                }}
                fullWidth
                value={state.last_name}
                onChange={handleInput}
                onFocus={handleFocus}
                onBlur={handleUnFocus}
                aria-label="Sukunimi"
                aria-labelledby="Opiskelijan tiedot sukunimi"
              />
            </Row>
            {state.course_variants.length === 0 ? null : (
              <div>
                <h2>{props.t("courseInfo")}</h2>
                <Row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.use_course_variant}
                        onChange={handleCourseVariantCheckbox}
                        name="use_course_variant"
                        value="1"
                      />
                    }
                    disabled={state.course_variants.length === 0}
                    label={props.t("useCourseVariantLabel")}
                  />
                </Row>

                <Row>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      id="select-course"
                      shrink={
                        state.course_variant ||
                        state.focused === "course_variant"
                      }
                    >
                      {props.t("courseVariant")}
                    </InputLabel>
                    <Select
                      key={state.use_course_variant}
                      disabled={!state.use_course_variant}
                      labelId="select-course"
                      label={props.t("courseVariant")}
                      name="course_variant"
                      value={state.course_variant}
                      onChange={handleInput}
                      onFocus={handleFocus}
                      onBlur={handleUnFocus}
                    >
                      <MenuItem value="" disabled>
                        {props.t("chooseCourse")}
                      </MenuItem>
                      {state.course_variants.map((x) => {
                        const key = x.organization
                        return (
                          <MenuItem value={key} key={key}>
                            {x.title}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Row>
                {state.course_variant && (
                  <>
                    <Row>
                      <TextField
                        variant="outlined"
                        type="text"
                        label={`${props.t("courseKey")}`}
                        name="course_key"
                        InputLabelProps={{
                          shrink:
                            state.course_key || state.focused === "course_key",
                        }}
                        fullWidth
                        value={state.course_key}
                        onChange={handleInput}
                        helperText={state.course_variant.fullName}
                        onFocus={handleFocus}
                        onBlur={handleUnFocus}
                      />
                      {state.error && state.errorObj.message && (
                        <ErrorMessage>{props.t("invalidKey")}</ErrorMessage>
                      )}
                    </Row>
                  </>
                )}
              </div>
            )}
          </div>
        </Loading>
        <Footer>
          <StyledButton
            onClick={onClick}
            disabled={state.submitting || state.error}
            loading={state.submitting}
            variant="contained"
            color="primary"
          >
            {props.t("save")}
          </StyledButton>
        </Footer>
      </Form>
      {state.error && (
        <InfoBox>
          <b>{props.t("fillRequired")}</b>
        </InfoBox>
      )}
    </FormContainer>
  )
}

export default withTranslation("common")(
  withSimpleErrorBoundary(CourseOptionsEditor),
)
